import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseFunctions'; // Make sure this path is correct
import { collection, getDocs } from 'firebase/firestore';

const DestinationList = () => {
  const [destinations, setDestinations] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'destinations'));
        const destinationList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDestinations(destinationList);
      } catch (error) {
        console.error('Error fetching destinations:', error);
        setError('Error fetching destinations. Please try again later.');
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Destinations</h2>
      <ul>
        {destinations.map((destination) => (
          <li key={destination.id}>
            <Link to={`/purchase/${destination.id}`}>{destination.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DestinationList;