import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Import Firestore for the Modular SDK
// Include other necessary imports, such as for Analytics if you're using that

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyALB3wnoQ0YONm9VG9Njz_1IP9tVlxgglM",
    authDomain: "yearbookbackend.firebaseapp.com",
    databaseURL: "https://yearbookbackend-default-rtdb.firebaseio.com",
    projectId: "yearbookbackend",
    storageBucket: "yearbookbackend.appspot.com",
    messagingSenderId: "257576644286",
    appId: "1:257576644286:web:414be3f7174f1e150cb8ef",
    measurementId: "G-LDKJ9ZY27H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore using the Modular SDK
const db = getFirestore(app);

// Export the db (Firestore) instance
export { app, db };
