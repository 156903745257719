import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DestinationList from './Components/DestinationList';
import ConfirmationPage from './Components/ConfirmationPage';
import PurchasePage from './Components/PurchasePage';
import './App.css';

function Header() {
  return (
    <header className="App-header" style={{ padding: '20px', textAlign: 'center' }}>
      <img 
        src={`${process.env.PUBLIC_URL}/NEW.png`} 
        alt="The Yearbook"
        style={{
          width: '50%',
          maxWidth: '200px',
          height: 'auto',
          borderRadius: '10px',
          border: '3px solid #ccc',
          marginBottom: '20px',
        }}
      />
      <h1 style={{
        margin: '0 auto',
        maxWidth: '90%',
        padding: '0 10px',
      }}>
        Welcome to The Yearbook Online
      </h1>
    </header>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<><Header /> <DestinationList /></>} />
          <Route path="/purchase/:id" element={<><Header /> <PurchasePage /></>} />
          <Route path="/success" element={<ConfirmationPage />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
