import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmationPage = () => {
    const [pin, setPin] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const query = useQuery();
    const sessionId = query.get('session_id');

    useEffect(() => {
        const functions = getFunctions();
        const confirmPaymentAndGeneratePin = httpsCallable(functions, 'confirmPaymentAndGeneratePin');

        if (!sessionId) {
            setError('No session ID provided.');
            setLoading(false);
            return;
        }

        confirmPaymentAndGeneratePin({ sessionId }).then(result => {
            if (result.data && result.data.pin) {
                setPin(result.data.pin);
                setLoading(false);
            } else {
                setError('No data received from the server.');
                setLoading(false);
            }
        }).catch(error => {
            console.error("Error confirming payment and generating PIN:", error);
            setError(`Failed to confirm payment and generate PIN: ${error.message}`);
            setLoading(false);
        });
    }, [sessionId]);

    if (error) {
        return <div style={{ textAlign: 'center', color: 'red', marginTop: '20px' }}>Error: {error}</div>;
    }

    return (
        <div style={{
            padding: '20px',
            textAlign: 'center',
            color: 'white',
            backgroundColor: '#333645',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: window.innerWidth > 600 ? '1rem' : '0.8rem'
        }}>
            <img 
                src={`${process.env.PUBLIC_URL}/NEW.png`}
                alt="NEW"
                style={{ maxWidth: window.innerWidth > 600 ? '30%' : '50%', borderRadius: '10px', marginBottom: '20px' }}
            />
            <h2 style={{ fontSize: window.innerWidth > 600 ? '2.5rem' : '2rem', marginBottom: '0.5rem' }}>Payment Successful!</h2>
            <p>Thank you for securing a digital copy of the yearbook.</p>
            <p>{loading ? 'Your Childs PIN: Loading...' : `Your Childs PIN: ${pin}`}</p>
            <p>Direct your child to download our app "The Yearbook" from the App Store, and enter the PIN to access their digital copy.</p>
            <p>A copy of this page has been sent to the email you provided with the payment information, for your convenience.</p>
        </div>
    );
};

export default ConfirmationPage;
