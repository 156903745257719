import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

const PurchasePage = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const videoRef = useRef(null);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    const functions = getFunctions();
    const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');

    try {
      const { data } = await createStripeCheckoutSession({ priceId: id });
      if (data && data.sessionId) {
        const stripe = await loadStripe('pk_test_51OvpD72MeEZZqCepCszumt6h92vF7fcAksqYDeLc2FlGFcRsT3FMCAzTDlvCTugIPEdNuYPZSWkKFpwd2EbFY9pE00wDlFZeo9');
        await stripe.redirectToCheckout({ sessionId: data.sessionId });
      } else {
        throw new Error('Failed to create checkout session.');
      }
    } catch (error) {
      setError('Payment failed: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
        setPlaying(true);
      } else {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  };

  return (
    <div>
      <p>A company dedicated to enhancing the quality of life.</p>
      <p>Our product offers a way to preserve your child's cherished memories with our exclusive digital copy of the yearbook.</p>
      <p>This timeless keepsake celebrates their journey, milestones, and unforgettable moments throughout {id}.</p>
      <h3 style={{ fontSize: '24px', textAlign: 'center', margin: '20px 0' }}>A sample of our App:</h3>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <video
          ref={videoRef}
          style={{ maxWidth: '360px', width: '100%', aspectRatio: '9 / 16' }}
          src={`${process.env.PUBLIC_URL}/0414.mp4`}
          onEnded={() => setPlaying(false)}
          controls={false}
        >
          Your browser does not support the video tag.
        </video>
        {!isMobile && (
          <button onClick={togglePlay} style={{
            fontSize: '18px',
            padding: '12px 24px',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginLeft: '20px',
          }}>
            {playing ? 'Pause Sample' : 'Play Sample'}
          </button>
        )}
      </div>
      {isMobile && (
        <button onClick={togglePlay} style={{
          fontSize: '18px',
          padding: '12px 24px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'block',
          margin: '0 auto',
        }}>
          {playing ? 'Pause Sample' : 'Play Sample'}
        </button>
      )}
      <p>(No photos or cover images of actual yearbooks are shown due to privacy standards)</p>
      <p>Secure your child's copy now by clicking "Proceed to Checkout" and purchasing a One-Time Pin.</p>
      {error && <div>Error: {error}</div>}
      {loading && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '20px'
        }}>
          Loading...
        </div>
      )}
      <button onClick={handlePayment} style={{
        fontSize: '18px',
        padding: '12px 24px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'block',
        margin: '20px auto'
      }}>
        Proceed to Checkout
      </button>
    </div>
  );
};

export default PurchasePage;
